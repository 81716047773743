import React, { FC } from 'react'

import { ButtonGroup } from '@mui/material'
import Stack from '@mui/material/Stack'
import { BILLING_CYCLE } from 'api/src/common/enums'
import { ProductPlan } from 'types/graphql'

import EnterpriseProduct from 'src/components/Billing/EnterpriseProduct/EnterpriseProduct'
import Product from 'src/components/Billing/Product/Product'
import Button from 'src/components/Library/Button/Button'

export interface ProductsViewProps {
  billingPeriod: BILLING_CYCLE
  setBillingPeriod: (billingPeriod: BILLING_CYCLE) => void
  products: ProductPlan[]
  setSelectedProduct: (product: ProductPlan) => void
  setCheckoutStep: (step: number) => void
}

const ProductsView: FC<ProductsViewProps> = ({
  billingPeriod,
  products,
  setSelectedProduct,
  setCheckoutStep,
}) => {
  return (
    <Stack spacing={3} justifyContent={'center'} alignItems={'center'}>
      <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
        <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Select Your Hub Subscription Plan
        </p>
      </div>
      <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
        Pick the best plan based on your business needs
      </p>
      {/*
      Billing forced to monthly temporarily
      <ButtonGroup disableElevation variant="contained">
        <Button
          variant={
            billingPeriod === BILLING_CYCLE.MONTHLY ? 'contained' : 'outlined'
          }
          onClick={() => {
            setBillingPeriod(BILLING_CYCLE.MONTHLY)
          }}
        >
          Monthly
        </Button>
        <Button
          variant={
            billingPeriod === BILLING_CYCLE.YEARLY ? 'contained' : 'outlined'
          }
          onClick={() => {
            setBillingPeriod(BILLING_CYCLE.YEARLY)
          }}
        >
          Yearly
        </Button>
      </ButtonGroup>
      */}
      <Stack spacing={2} direction={'row'}>
        {products.map((product: ProductPlan, index) => (
          <Product
            key={product.id}
            product={product}
            onClick={() => {
              setSelectedProduct(product)
              if (product.metadata.tier === '4') {
                setCheckoutStep(2)
              } else {
                setCheckoutStep(1)
              }
            }}
            newSale
            index={index}
            billingPeriod={billingPeriod}
          />
        ))}
        <EnterpriseProduct />
      </Stack>
    </Stack>
  )
}

export default ProductsView
