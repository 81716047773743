import React, { FC, useEffect } from 'react'

import { ArrowLeftIcon, ShoppingCartIcon } from '@heroicons/react/24/outline'
import { Grid2 as Grid } from '@mui/material'
import Stack from '@mui/material/Stack'
import { BILLING_CYCLE } from 'api/src/common/enums'
import { CurrentProduct, ProductPlan } from 'types/graphql'

import Product from 'src/components/Billing/Product/Product'
import { CustomAddon } from 'src/components/Billing/ProductPlansCell/ProductPlansCell'
import Button from 'src/components/Library/Button/Button'

interface AddonsViewProps {
  addons: ProductPlan[]
  setCheckoutStep: (step: number) => void
  billingPeriod: BILLING_CYCLE
  setSelectedAddons: (addon: CustomAddon[]) => void
  selectedAddons: CustomAddon[]
  selectedProduct: ProductPlan
}

const AddonsView: FC<AddonsViewProps> = ({
  addons,
  setCheckoutStep,
  billingPeriod,
  selectedProduct,
}) => {
  const currentProduct = selectedProduct as unknown as CurrentProduct
  useEffect(() => {
    if (selectedProduct && addons?.length < 1) {
      setCheckoutStep(2)
    }
  }, [addons, selectedProduct])

  if (addons?.length < 1) {
    return null
  }
  return (
    <Grid container spacing={2} className={'w-full'}>
      <Grid size={{ xs: 12 }}>
        <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Confirm Your Selection
          </p>
        </div>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Stack spacing={2} direction={'row'} className={'mx-auto max-w-3xl'}>
          <Button
            onClick={() => setCheckoutStep(0)}
            variant={'text'}
            startIcon={<ArrowLeftIcon className={'h-4 w-4'} />}
            fullWidth={false}
          >
            Back
          </Button>
        </Stack>
      </Grid>
      <Grid container size={{ xs: 12 }} justifyContent={'center'}>
        <Grid>
          <Product
            product={selectedProduct}
            billingPeriod={billingPeriod}
            currentProduct={currentProduct}
          />
          <Stack
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
            className={'mt-4'}
          >
            {/*
            <Button
              variant={'outlined'}
              onClick={() => setSelectedAddons([])}
              className={'rounded-lg'}
            >
              Clear Selection{' '}
            </Button>
            */}
            <Button
              startIcon={<ShoppingCartIcon className={'h-6 w-6 stroke-2'} />}
              onClick={() => setCheckoutStep(2)}
              className={'rounded-lg'}
            >
              Checkout
            </Button>
          </Stack>
        </Grid>
        {/*
        <Grid>
          <Stack spacing={2}>
            {addons.map((addon) => (
              <Addon
                addon={addon}
                key={addon.id}
                billingPeriod={billingPeriod}
                isSelected={selectedAddons?.some((x) => x.id === addon.id)}
                onClick={(passedAddon: CustomAddon) => {
                  if (selectedAddons.length > 0) {
                    const passedAddonInArray = selectedAddons.find(
                      (x) => x.upTo === passedAddon.upTo,
                    )
                    if (passedAddonInArray) {
                      setSelectedAddons([])
                    } else {
                      setSelectedAddons([passedAddon as CustomAddon])
                    }
                  } else {
                    setSelectedAddons([passedAddon as CustomAddon])
                  }
                }}
                newSale
                currentCustomerAddon={{
                  ...selectedAddons[0],
                  quantity: selectedAddons[0]?.upTo,
                }}
              />
            ))}
          </Stack>
        </Grid>
        */}
      </Grid>
    </Grid>
  )
}

export default AddonsView
