import { FC } from 'react'

import { PlusIcon, ShoppingBagIcon } from '@heroicons/react/24/outline'
import { Tooltip } from '@mui/material'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'

import { navigate, routes } from '@redwoodjs/router'

import Button from 'src/components/Library/Button/Button'

interface SubscriptionItemProps {
  item: any
}

interface SubscriptionButtonProps {
  onClick(): void
  isAddon: boolean
  itemId: string
}

const SubscriptionItem: FC<SubscriptionItemProps> = ({ item }) => {
  const isAddon = item.plan.product.metadata?.type === 'addon'

  return (
    <Card elevation={0} variant={'outlined'}>
      <CardHeader
        title={
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <div className="text-gray-900 text-lg font-['SF Pro'] leading-normal">
              {item.plan.product.name}
            </div>
            <Tooltip title={'Quantity'} arrow placement={'top'}>
              <Chip
                size={'small'}
                label={
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <ShoppingBagIcon className={'w-4 h-4 '} />
                    <div className="text-black text-xs leading-none">
                      {item.quantity}
                    </div>
                  </Stack>
                }
              />
            </Tooltip>
          </Stack>
        }
        action={
          <Button
            onClick={() =>
              isAddon
                ? navigate(
                    routes.clientAddonUpgrade({
                      addonId: item.price.product,
                    }),
                  )
                : navigate(routes.clientSubscriptionUpgrade())
            }
            variant={'outlined'}
            startIcon={<PlusIcon className={'w-4 h-4'} />}
          >
            Upgrade
          </Button>
        }
      />
    </Card>
  )
}

export default SubscriptionItem
