import type React from 'react'

import { Metadata } from '@redwoodjs/web'

import Billing from 'src/components/Billing/Billing'
import Empty from 'src/components/Library/Empty/Empty'
import PageHeader from 'src/components/PageHeader/PageHeader'
import { useAuth } from 'web/src/Providers'

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >
    }
  }
}
const SettingsClientBillingPage = () => {
  const { currentUser } = useAuth()
  const client = currentUser.parentData
  return (
    <>
      <Metadata
        title="Settings - Client - Billing"
        description="Settings - Client - Billing"
      />
      <PageHeader title="Billing" parentDataTestId="client-settings-page" />
      {client.manualPayment ? (
        <div className="flex h-[calc(100vh-64px)]">
          <div className="grow p-1 grid place-items-center">
            <Empty
              title="Manual Payment"
              description="You are currently on a manual payment plan. Please contact your account manager to update your payment plan."
            />
          </div>
        </div>
      ) : (
        <div className="mt-4 sticky top-0">
          <Billing />
        </div>
      )}
    </>
  )
}

export default SettingsClientBillingPage
