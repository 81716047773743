import React, { FC } from 'react'

import { useLazyQuery } from '@apollo/client'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { CardContent, Tooltip } from '@mui/material'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Stack from '@mui/material/Stack'
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid'
import { captureException } from '@sentry/browser'
import dayjs from 'dayjs'
import { BillingDashboardQuery } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import BankAccount from 'src/components/Billing/BankAccount/BankAccount'
import BillingPlan from 'src/components/Billing/BillingPlan/BillingPlan'
import CreditCard from 'src/components/Billing/CreditCard/CreditCard'
import StripeRedirect from 'src/components/Billing/StripeRedirect/StripeRedirect'
import SubscriptionItem from 'src/components/Billing/SubscriptionItem/SubscriptionItem'
import { default as EmptyData } from 'src/components/Library/Empty'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import { openWindowWithBlockCheck } from 'src/lib/helpers'
import { UPDATE_PAYMENT_METHOD } from 'src/lib/queries/Settings/Billing/ProductPlans'
import { formatCurrency } from 'src/Util'

export const QUERY = gql`
  query BillingDashboardQuery {
    dashboardData {
      id
      periodEnd
      items
      paymentMethod {
        card {
          brand
          last4
          expMonth
          expYear
        }
        bankAccount {
          last4
          bsb
        }
      }
      invoices {
        id
        number
        total
        effectiveAt
        paid
        invoicePdf
        finalisedAt
        currency
      }
      status
      isFixedPrice
    }
    memberships: membershipsByClient {
      id
      user {
        id
        status
      }
      isActive
      deactivatedAt
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => {
  return (
    <EmptyData
      title={'Whoops...'}
      description={'It appears that your data is missing'}
    />
  )
}

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

type Props = CellSuccessProps<BillingDashboardQuery>

export const Success: FC<Props> = ({ dashboardData, memberships }) => {
  const subscriptionEndDate = dayjs(dashboardData.periodEnd * 1000).format(
    'DD/MM/YYYY',
  )
  const invoiceItem: any = dashboardData.items[0]
  const addons = dashboardData.items.filter(
    (item: any) => item.plan.product.metadata.type === 'addon',
  )

  const products = dashboardData.items.filter(
    (item: any) => item.plan.product.metadata.type !== 'addon',
  )

  const activeMemberships = memberships.filter(
    (membership) =>
      (membership.user.status === 'ACTIVE' ||
        membership.user.status === 'INVITED') &&
      membership.isActive === true,
  )

  const onDownload = (invoice: { invoicePdf: string; number: any }) => {
    const link = document.createElement('a')
    link.href = invoice?.invoicePdf
    link.download = `invoice-${invoice.number}.pdf`
    link.click()
  }

  const columns: GridColDef[] = [
    {
      field: 'total',
      headerName: 'Amount',
      valueGetter: (_value, row) =>
        formatCurrency(row.total / 100, 'en-AU', 'AUD'),
    },
    { field: 'paid', headerName: 'Paid', type: 'boolean', flex: 1 },
    { field: 'number', headerName: 'Invoice Number', flex: 1 },
    {
      field: 'Finalised',
      flex: 1,
      valueGetter: (_value, row) => {
        return `${dayjs(dayjs(row.effectiveAt * 1000)).format('DD/MM/YYYY')}`
      },
    },
    {
      field: 'download',
      type: 'actions',
      width: 40,
      getActions: (params) => [
        <Tooltip title={'Download'} arrow placement="top" key={params.id}>
          <GridActionsCellItem
            icon={<ArrowDownTrayIcon className={'h-4 w-4'} />}
            label="Download"
            onClick={() => {
              onDownload(params.row)
            }}
          />
        </Tooltip>,
      ],
    },
  ]

  const totalCost = dashboardData.items
    .map((item: any) => (item.price.unit_amount * item.quantity) / 100)
    .reduce((a, b) => a + b)

  const [
    updatePaymentSession,
    {
      called: paymentUpdateCalled,
      loading: paymentUpdateLoading,
      error: paymentUpdateError,
    },
  ] = useLazyQuery(UPDATE_PAYMENT_METHOD, {
    onCompleted: ({ updatePaymentMethod }) => {
      openWindowWithBlockCheck(updatePaymentMethod, '_self')
    },
    onError: (error) => {
      captureException(error)
    },
  })

  return (
    <div className={'flex justify-center'}>
      <Stack spacing={2} className={'w-[900px]'}>
        <Stack spacing={2} direction={'row'} alignItems={'center'}>
          <BillingPlan
            status={dashboardData.status}
            totalCost={totalCost}
            billingPeriod={invoiceItem.price?.recurring?.interval}
            currency={'AUD'}
            usage={
              // @ts-expect-error untyped
              (activeMemberships.length / dashboardData.items[0].quantity) * 100
            }
            nextInvoice={subscriptionEndDate}
            activeMembers={activeMemberships.length}
          />
          {dashboardData.paymentMethod.card ? (
            <CreditCard
              creditCard={dashboardData.paymentMethod.card}
              updateCard={() => {
                updatePaymentSession()
              }}
            />
          ) : (
            <BankAccount
              bankAccount={dashboardData.paymentMethod.bankAccount}
              updatePayment={updatePaymentSession}
            />
          )}
        </Stack>
        {products.map((item: any) => (
          <SubscriptionItem item={item} key={item.id} />
        ))}
        {addons.map((addon: any) => (
          <SubscriptionItem item={addon} key={addon.id} />
        ))}
        {/*
        {addons.length === 0 && (
          <Button
            className={'h-24'}
            variant={'outlined'}
            onClick={() => navigate(routes.clientAddonPurchase())}
          >
            Purchase Addons
          </Button>
        )}
          */}

        <Card elevation={0} variant={'outlined'}>
          <CardHeader
            className={'pb-0'}
            title={
              <h1 className="font-['SF Pro'] text-lg leading-normal text-gray-900">
                Invoices
              </h1>
            }
          />
          <CardContent className={'pt-0'}>
            <DataGrid
              sx={{
                '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                border: 'none',
              }}
              autoHeight
              columns={columns}
              rows={dashboardData.invoices}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              disableRowSelectionOnClick
              disableColumnMenu
              pageSizeOptions={[5]}
            />
          </CardContent>
        </Card>
      </Stack>
      <StripeRedirect isRedirecting={paymentUpdateCalled} />
    </div>
  )
}
