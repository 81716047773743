import { FC, ReactElement, ReactNode } from 'react'

interface CaseProps {
  children?: ReactNode
  when: string | number
}

interface DefaultProps {
  children?: ReactNode
  when?: never
}

interface SwitchProps {
  condition: undefined | string | number
  children?:
    | ReactElement<CaseProps | DefaultProps>
    | ReactElement<CaseProps | DefaultProps>[]
}

interface ViewSwitchProps extends FC<SwitchProps> {
  Case: FC<CaseProps>
  Default: FC<DefaultProps>
}

export const ViewSwitch: ViewSwitchProps = ({ condition, children }) => {
  if (!children) {
    return null
  }

  const arrayOfChildren = Array.isArray(children) ? children : [children]
  const cases = arrayOfChildren.filter(
    (child) => child.props.when === condition,
  )
  const defaultCases = arrayOfChildren.filter(
    (child) => child.props.when === undefined,
  )
  if (defaultCases.length > 1) {
    throw new Error('Only one <ViewSwitch.Default> is allowed')
  }
  const defaultCase = defaultCases[0]

  return cases.length > 0 ? <>{cases}</> : <>{defaultCase}</>
}

ViewSwitch.Case = ({ children }) => {
  return <>{children}</>
}

ViewSwitch.Default = ({ children }) => {
  return <>{children}</>
}
