import { FC } from 'react'

import Stack from '@mui/material/Stack'
import { Card } from 'types/graphql'

import Button from 'src/components/Library/Button/Button'
import { Amex, MasterCard, Visa } from 'web/src/lib/svg/SvgComponents'

enum creditCardBrands {
  AMERICAN_EXPRESS = 'American Express',
  VISA = 'visa',
  MASTERCARD = 'mastercard',
}
interface CreditCardProps {
  creditCard: Card
  updateCard: () => void
}

const CreditCard: FC<CreditCardProps> = ({ creditCard, updateCard }) => {
  let brandIcon = null
  const _overide = true
  if (creditCard.brand === creditCardBrands.MASTERCARD) {
    brandIcon = <MasterCard className={'w-14 h-14'} />
  } else if (creditCard.brand === creditCardBrands.VISA) {
    brandIcon = <Visa className={'w-14 h-14'} />
  } else if (creditCard.brand !== creditCardBrands.AMERICAN_EXPRESS) {
    brandIcon = <Amex className={'w-14 h-14'} />
  }

  return (
    <div className="w-96 h-52 bg-gradient-to-l from-indigo-400 to-indigo-300 rounded-xl relative text-white hover:shadow-2xl transition-transform transform hover:scale-105">
      <div className="w-full px-8 absolute top-8">
        <Stack
          alignItems={'center'}
          justifyContent={'space-between'}
          direction={'row'}
        >
          <div>{brandIcon}</div>
          <Button
            variant={'outlined'}
            fullWidth={false}
            className={'h-1/2 text-white border-white hover:text-indigo-800'}
            onClick={updateCard}
          >
            Update
          </Button>
        </Stack>
        <div className="pt-4">
          <p className="font-medium tracking-widest text-xl">
            **** **** **** {creditCard.last4}
          </p>
        </div>
        <div className="pt-6 pr-6">
          <Stack spacing={1} alignItems={'center'} direction={'row'}>
            <p className="font-light text-xs">Expiry</p>
            <p className="font-medium tracking-wider text-sm">{`${creditCard.expMonth}/${creditCard.expYear}`}</p>
            <p className="font-light text-xs">CVV</p>
            <p className="font-bold tracking-more-wider text-sm">* * *</p>
          </Stack>
        </div>
      </div>
    </div>
  )
}

export default CreditCard
