import React, { useState } from 'react'

import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { isEmailValid } from 'api/src/common/utils'
import { useBoolean } from 'usehooks-ts'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import BillingDashboardCell from 'src/components/Billing/BillingDashboardCell'
import ProductPlansCell from 'src/components/Billing/ProductPlansCell'
import Modal from 'src/components/Modal/Modal'
import { UPDATE_CLIENT_MUTATION } from 'src/components/SuperAdmin/Client/EditClientCell/EditClientCell'
import { CREATE_CUSTOMER } from 'src/lib/queries/Settings/Billing/ProductPlans'
import { useAuth } from 'web/src/Providers'

const testData = [
  {
    id: 1,
    status: 'LEGACY',
    stripeSubscriptionId: 'sub_123',
    clientId: 1,
  },
]

const PaymentPreferences = ({ client, reauthenticate }) => {
  const [billingEmail, setBillingEmail] = useState('')
  const [errorLabel, setErrorLabel] = useState('')
  const loading = useBoolean(false)
  const [createCustomer] = useMutation(CREATE_CUSTOMER, {
    onCompleted: ({ createCustomer }) => {
      loading.setFalse()
      updateClient({
        variables: {
          id: client.id,
          input: { stripeCustomerId: createCustomer },
        },
      }).then(() => {
        reauthenticate()
        navigate(routes.settingsClientBilling())
      })
    },
    onError: (error) => {
      loading.setFalse()
      toast.error(error.message, {
        duration: 5000,
        className: 'flex-column',
      })
    },
  })
  const handleUpdate = (e) => {
    setBillingEmail(e.target.value)
  }

  const [updateClient] = useMutation(UPDATE_CLIENT_MUTATION)

  const displayForm = useBoolean(false)

  const handleNewCustomer = async () => {
    if (!isEmailValid(billingEmail)) {
      setErrorLabel('Please enter a valid email')
      return
    }
    setErrorLabel('')
    loading.setTrue()
    await createCustomer({
      variables: {
        email: billingEmail,
        name: client.name,
      },
    })
  }

  return (
    <div className={'flex justify-center m-12'}>
      <Modal
        open={true}
        onClose={displayForm.setFalse}
        title={'Register'}
        footerVisible={true}
        onConfirm={handleNewCustomer}
        cancelButtonVisible={false}
        loading={loading.value}
      >
        <div className={'w-full flex justify-center items-center mb-2'}>
          <div className={'w-1/2'}>
            <Stack spacing={2}>
              <h2
                className={
                  'font-normal text-sm leading-3 uppercase text-gray-600'
                }
              >
                Billing Email
              </h2>
              <TextField
                variant="outlined"
                label={errorLabel}
                error={errorLabel.length > 0}
                size={'small'}
                value={billingEmail}
                onChange={handleUpdate}
              />
            </Stack>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const Billing = () => {
  const { currentUser, reauthenticate } = useAuth()
  const client = currentUser.parentData
  const override = false
  if (
    !currentUser.parentData.stripeSetup &&
    !override &&
    !currentUser.parentData.stripeCustomerId
  ) {
    return (
      <PaymentPreferences
        client={currentUser.parentData}
        reauthenticate={reauthenticate}
      />
    )
  }

  if (
    (client.stripeCustomerId && !currentUser.parentData.stripeSetup) ||
    override
  ) {
    return (
      <div className="flex justify-center">
        <ProductPlansCell />
      </div>
    )
  }

  return (
    <div className={'m-4'}>
      <BillingDashboardCell />
    </div>
  )
}

export default Billing
