export const CREATE_CHECKOUT_SESSION = gql`
  mutation CreateCheckoutSession($priceId: String!, $addons: [AddonInput!]) {
    checkoutSession(priceId: $priceId, addons: $addons)
  }
`

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($email: String!, $name: String!) {
    createCustomer(email: $email, name: $name)
  }
`

export const UPDATE_PAYMENT_METHOD = gql`
  query UpdatePaymentMethod {
    updatePaymentMethod
  }
`
