import { FC } from 'react'

import { BuildingLibraryIcon } from '@heroicons/react/24/outline'
import Stack from '@mui/material/Stack'
import { BankAccount as BankAccountType } from 'types/graphql'

import Button from 'src/components/Library/Button/Button'

interface BankAccountProps {
  bankAccount: BankAccountType
  updatePayment: () => void
}

const BankAccount: FC<BankAccountProps> = ({ bankAccount, updatePayment }) => {
  return (
    <div className="w-96 h-52 bg-gradient-to-l from-indigo-400 to-indigo-300 rounded-xl relative text-white hover:shadow-2xl transition-transform transform hover:scale-105">
      <div className="w-full px-8 absolute top-8">
        <Stack
          alignItems={'center'}
          justifyContent={'space-between'}
          direction={'row'}
        >
          <BuildingLibraryIcon className={'h-12 w-12 text-white'} />
          <Button
            variant={'outlined'}
            fullWidth={false}
            className={'h-1/2 text-white border-white hover:text-indigo-800'}
            onClick={updatePayment}
          >
            Update
          </Button>
        </Stack>
        <Stack spacing={1} direction={'column'} justifyContent="space-around">
          <div className="pt-4">
            <p className="font-light text-xs">Account Number</p>
            <p className="font-medium tracking-widest text-xl">
              ••••{bankAccount.last4}
            </p>
          </div>
          <div className="pr-6">
            <p className="font-light text-xs">BSB</p>
            <p className="font-medium tracking-widest text-xl">
              {bankAccount.bsb.slice(0, 3)} - {bankAccount.bsb.slice(3, 6)}
            </p>
          </div>
        </Stack>
      </div>
    </div>
  )
}

export default BankAccount
