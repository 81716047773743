import Stack from '@mui/material/Stack'

import Loading from 'src/components/Library/Loading/Loading'
import Modal from 'src/components/Modal/Modal'

interface StripeRedirectProps {
  isRedirecting: boolean
}

const StripeRedirect = ({ isRedirecting }) => {
  return (
    <Modal
      open={isRedirecting}
      closeButtonVisible={false}
      dialogClassName={'h-60 pt-6 justify-center'}
    >
      <Stack spacing={2} justifyContent="center" alignItems={'center'}>
        <div className="text-gray-900 text-2xl leading-normal">
          Redirecting to Stripe...
        </div>
        <div className="text-gray-400 text-lg">
          please wait while we redirect you to Stripe to continue
        </div>
        <Loading />
      </Stack>
    </Modal>
  )
}

export default StripeRedirect
